import * as React from 'react'
import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet'
import { LatLngTuple, icon, divIcon } from 'leaflet'
import { TrackerPosition } from '@types'
import { useTrackerContext } from 'hooks/useTrackerContext'
import iconUrl from 'leaflet/dist/images/marker-icon.png'
import iconShadowUrl from 'leaflet/dist/images/marker-shadow.png'

const defaultLatLon: LatLngTuple = [35.68141918995937, 139.76709261170504] // This coordinate is Tokyo station

type Props = {
  center: LatLngTuple
  zoom: number
}

const MapFryTo: React.FC<Props> = ({ center, zoom }) => {
  const map = useMap()
  map.flyTo(center, zoom)
  return <span></span>
}

export const Map: React.FC = () => {
  const { selected, positions } = useTrackerContext()
  const plist = positions || []
  let centerLatLon: LatLngTuple = defaultLatLon
  if (plist.length > 0) {
    plist.sort((a, b) => {
      return a.datetime.getTime() - b.datetime.getTime()
    })
    centerLatLon = [plist[0].latitude, plist[0].longitude]
  }
  const colorMarker = (mark: boolean) => {
    if (mark) {
      return divIcon({
        html: '',
        className: 'color_palette solid_icon',
        iconSize: [0, 0],
      })
    } else {
      return icon({
        iconUrl: iconUrl,
        shadowUrl: iconShadowUrl,
      })
    }
  }
  return (
    <React.Fragment>
      <MapContainer center={centerLatLon} zoom={15} scrollWheelZoom={false}>
        <MapFryTo center={centerLatLon} zoom={15} />
        <TileLayer
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {plist.map((pos: TrackerPosition, i: number) => (
          <Marker
            position={[pos.latitude, pos.longitude]}
            key={`marker-${i + 1}`}
            icon={colorMarker(i === plist.length - 1)}
          >
            <Popup>
              <div>No. {i + 1}</div>
              <div>IMEI: {selected}</div>
              <div>日付: {pos.datetime.toISOString()}</div>
              <div>バッテリー残量: {pos.batteryStatus}</div>
            </Popup>
          </Marker>
        ))}
      </MapContainer>
    </React.Fragment>
  )
}
