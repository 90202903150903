/**
 * custom hook to get tracker list from backend server.
 * tracker list is included imei and labels of the NB IoT GNSS trakcers
 */

import React, { createContext, useState, useContext, useEffect } from 'react'
import { fetchTrackerList, fetchTrackerPositions } from 'backend/api'
import { TrackerLabel, TrackerPosition } from '@types'
import { useSessionContext } from 'hooks/useSessionContext'

interface TrackerContextType {
  trackers: TrackerLabel[]
  trackersCount: number
  selected: string
  updateTrackers?: Function
  addTracker?: Function
  removeTracker?: Function
  selectTracker?: Function
  positions?: TrackerPosition[]
}
interface TrackerContextProviderParam {
  children: React.ReactNode
}

const getImeiList = async (groupId: string, token: string) => {
  const res = await fetchTrackerList(groupId, token)
  let labels: TrackerLabel[] = []
  if (res.status === 200) {
    const l: TrackerLabel[] = res.data.imeiList
    labels = l
  }
  return labels
}

const fetchPosition = async (imei: string, groupId: string, token: string) => {
  if (imei.length === 0) return []
  const res = await fetchTrackerPositions(imei, groupId, token)
  let pos: TrackerPosition[] = []
  if (res.status === 200) {
    pos = res.data
  }
  return pos
}

const TrackerContext: React.Context<TrackerContextType> = createContext({
  trackers: [{ label: '', imei: '' }],
  trackersCount: 0,
  selected: '',
})

export const TrackerContextProvider: React.FC<TrackerContextProviderParam> = ({
  children,
}) => {
  const [trackers, setTrackerList] = useState<TrackerLabel[]>([])
  const [selected, setTrackerImei] = useState<string>('')
  const [positions, setTrackerPositions] = useState<TrackerPosition[]>([])
  const [trackersCount, setTrackersCount] = React.useState<number>(
    trackers.length,
  )
  const { signInState } = useSessionContext()
  const groupId = signInState?.groupId ?? ''
  const token = signInState?.idToken ?? ''

  const selectTracker = (imei: string) => {
    setTrackerImei(imei)
  }

  const updateTrackers = async () => {
    if (groupId === '') {
      return []
    }

    const trackers = await getImeiList(groupId, token)
    setTrackerList(trackers)
    setTrackersCount(trackers.length)
    return trackers
  }

  useEffect(() => {
    if (selected !== '' && groupId !== '' && token !== '') {
      fetchPosition(selected, groupId, token).then((pos) => {
        setTrackerPositions(pos)
      })
    } else {
      setTrackerPositions([])
    }
  }, [selected, groupId, token])

  return (
    <TrackerContext.Provider
      value={{
        trackers,
        trackersCount,
        selected,
        selectTracker,
        updateTrackers,
        positions,
      }}
    >
      {children}
    </TrackerContext.Provider>
  )
}

export const useTrackerContext = () => useContext(TrackerContext)
