import React, { createContext, useState, useContext, useEffect } from 'react'
import {
  fetchTrackerDeviceInfo,
  fetchTrackerSetting,
  sendTrackerSettings,
} from 'backend/api'
import { register } from 'hooks/useRegistrationContext'
import { useSessionContext } from 'hooks/useSessionContext'
import {
  TrackerDeviceInfo,
  TrackerContextProviderParam,
  TrackerSettings,
  DeviceSettings,
  TrackerLabel,
} from '@types'

interface TrackerSettingContextType {
  deviceinfo?: TrackerDeviceInfo
  selected?: TrackerLabel
  settings?: TrackerSettings
  submitSettings?: Function
  selectSetting?: Function
  getVisible?: Function
}

/**
 * fetch tracker's settings from backend server.
 * @returns
 */
const fetchSettings = async (
  imei: string,
  groupId: string,
  token: string,
): Promise<DeviceSettings | undefined> => {
  if (imei.length === 0) return
  const res = await fetchTrackerSetting(imei, groupId, token)
  let setting
  if (res.status === 200) {
    setting = res.data
  }
  return setting
}
/**
 * post tracker's settings to backend server.
 * @returns
 */
const sendSettings = async (
  imei: string,
  body: object,
  groupId: string,
  token: string,
) => {
  if (imei.length === 0) return
  const res = await sendTrackerSettings(imei, body, groupId, token)
  if (res.status === 202) {
    return true
  }
  return false
}

/**
 * fetch tracker's device info from backend server.
 * @returns
 */
const fetchDeviceInfo = async (
  imei: string,
  groupId: string,
  token: string,
): Promise<TrackerDeviceInfo | undefined> => {
  if (imei.length === 0) return
  const res = await fetchTrackerDeviceInfo(imei, groupId, token)
  let info
  if (res.status === 200) {
    info = res.data
  }
  return info
}

let SettingContext: React.Context<TrackerSettingContextType>

SettingContext = createContext({})

export const SettingContextProvider: React.FC<TrackerContextProviderParam> = ({
  children,
}) => {
  const [settings, setSettings] = useState<TrackerSettings>()
  const [selected, setTrackerLabel] = useState<TrackerLabel>()
  const [deviceinfo, setDeviceInfo] = useState<TrackerDeviceInfo>()
  const [visible, setVisible] = useState(false)
  const { signInState } = useSessionContext()
  const groupId = signInState?.groupId ?? ''
  const token = signInState?.idToken ?? ''

  const submitSettings = async (newSettings: TrackerSettings) => {
    setSettings(newSettings)
    if (!selected || !selected.imei) return false
    const r0 = await sendSettings(
      selected.imei,
      newSettings.deviceSetting,
      groupId,
      token,
    )
    const r1 = await register(
      { imei: selected.imei, label: selected.label },
      groupId,
      token,
    )
    return r0 && r1
  }

  const selectSetting = (label: TrackerLabel) => {
    setTrackerLabel(label)
    setVisible(label !== undefined)
  }

  const getVisible = () => {
    return visible
  }

  useEffect(() => {
    if (!selected || !selected.imei) return
    fetchSettings(selected.imei, groupId, token).then((setting) => {
      if (!setting) return
      let s = settings
      if (!s) {
        s = {
          label: selected.label,
          deviceSetting: setting,
        }
      } else {
        s.deviceSetting = setting
      }
      setSettings(s)
    })
  }, [selected, groupId, settings, token])

  useEffect(() => {
    if (!selected || !selected.imei) return
    fetchDeviceInfo(selected.imei, groupId, token).then((info) => {
      if (!info) return
      setDeviceInfo(info)
    })
  }, [selected, groupId, token])

  return (
    <SettingContext.Provider
      value={{
        deviceinfo,
        selected,
        settings,
        submitSettings,
        selectSetting,
        getVisible,
      }}
    >
      {children}
    </SettingContext.Provider>
  )
}

export const useSettingContext = () => useContext(SettingContext)
