/**
 * custom hook to regist or unregist tracker's IMEI.
 * @returns
 */

import React, { createContext, useContext } from 'react'
import { sendRegistration, sendUnregistration } from 'backend/api'
import { TrackerRegistration } from '@types'
import { useTrackerContext } from 'hooks/useTrackerContext'
import { useSettingContext } from 'hooks/useSettingContext'
import { useSessionContext } from 'hooks/useSessionContext'

interface RegistrationContextType {
  registerTracker?: Function
  unregisterTracker?: Function
  unregisteredCount?: number
}

interface RegistrationContextProviderParam {
  children: React.ReactNode
}

const unregister = async (
  entry: TrackerRegistration,
  groupId: string,
  token: string,
): Promise<boolean> => {
  if (!entry) return false
  const res = await sendUnregistration(entry.imei, groupId, token)
  if (res.status === 204) {
    return true
  }
  return false
}

export const register = async (
  entry: TrackerRegistration,
  groupId: string,
  token: string,
): Promise<boolean> => {
  if (!entry) return false
  const res = await sendRegistration(entry.imei, entry, groupId, token)
  if (res.status === 201 || res.status === 200) {
    return true
  }
  return false
}

let RegistrationContext: React.Context<RegistrationContextType>

RegistrationContext = createContext({})

export const RegistrationContextProvider: React.FC<
  RegistrationContextProviderParam
> = ({ children }) => {
  const { updateTrackers, selectTracker, selected } = useTrackerContext()
  const { selected: selectedLabel, selectSetting } = useSettingContext()
  const { signInState } = useSessionContext()
  const update = updateTrackers ? updateTrackers : () => {}
  const selectFn = selectTracker ? selectTracker : () => {}
  const selectSettingFn = selectSetting ? selectSetting : () => {}
  const groupId = signInState?.groupId ?? ''
  const token = signInState?.idToken ?? ''

  const registerTracker = async (entry: TrackerRegistration) => {
    const res = await register(entry, groupId, token)
    if (res) await update()
    return res
  }

  const unregisterTracker = async (entry: TrackerRegistration) => {
    const res = await unregister(entry, groupId, token)
    if (res) {
      await update()
      if (selected === entry.imei) {
        selectFn('')
      }
      if (selectedLabel?.imei === entry.imei) {
        selectSettingFn()
      }
    }
    return res
  }

  return (
    <RegistrationContext.Provider
      value={{
        registerTracker,
        unregisterTracker,
      }}
    >
      {children}
    </RegistrationContext.Provider>
  )
}

export const useRegistrationContext = () => useContext(RegistrationContext)
