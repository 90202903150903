import * as React from 'react'
//import Link from '@mui/material/Link'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { TrackerPosition } from '@types'
import { useTrackerContext } from 'hooks/useTrackerContext'

export const Positions: React.FC = () => {
  const { positions } = useTrackerContext()
  const plist = positions || []
  return (
    <React.Fragment>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>日付</TableCell>
            <TableCell>緯度</TableCell>
            <TableCell>経度</TableCell>
            <TableCell>電池残量</TableCell>
            <TableCell>IMEI</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {plist.map((pos: TrackerPosition, i: number) => (
            <TableRow key={i}>
              <TableCell>{pos.datetime.toISOString()}</TableCell>
              <TableCell>{pos.latitude}</TableCell>
              <TableCell>{pos.longitude}</TableCell>
              <TableCell>{pos.batteryStatus}</TableCell>
              <TableCell>{pos.imei}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </React.Fragment>
  )
}
