import { Amplify, Auth } from 'aws-amplify'
import { CognitoAttributes } from '@aws-amplify/ui'
import { log } from './api'

// Amplify.Logger.LOG_LEVEL = 'DEBUG'
export interface SignInState {
  error: Error | null
  groupId: string
  idToken: string
}

const USE_COGNITO =
  (process.env.REACT_APP_USE_COGNITO ?? '').toLowerCase() === 'true' ?? false

const USER_POOL_ID = process.env.REACT_APP_USER_POOL_ID ?? ''
const USER_CLIENT_ID = process.env.REACT_APP_USER_CLIENT_ID ?? ''
const ID_POOL_ID = process.env.REACT_APP_ID_POOL_ID ?? ''
Amplify.configure({
  Auth: {
    identityPoolId: ID_POOL_ID, // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
    region: 'us-east-1', // (required) - Region where Amazon Cognito project was created
    userPoolId: USER_POOL_ID, // (optional) -  Amazon Cognito User Pool ID
    userPoolWebClientId: USER_CLIENT_ID, // (optional) - Amazon Cognito App Client ID (App client secret needs to be disabled)
    //aws_mandatory_sign_in: 'enable' // (optional) - Users are not allowed to get the aws credentials unless they are signed in
    signUpVerificationMethod: 'code',
    // cookieStorage: {
    //   path: '/',
    //   expires: 1,
    //   sameSite: 'lax',
    //   secure: true,
    //   domain: '.wavee-plus.jp',
    // },
  },
})

export const getGroupIdfromAttributes = (attributes?: CognitoAttributes) => {
  let groupId = ''
  let token: string = ''
  if (!attributes) {
    return {
      error: null,
      groupId: groupId,
      idToken: token,
    }
  }
  const key = Object.keys(attributes).find((key) => key === 'custom:groupId')
  if (key) {
    groupId = attributes[key]
  }

  log(`groupId from attributes ${groupId}`)
  return {
    error: null,
    groupId: groupId,
    idToken: token,
  }
}

async function fetchTokenAndGroupIdfromUserPool(): Promise<SignInState> {
  log(`fetchTokenAndGroupIdfromUserPool use cognito=${USE_COGNITO}`)
  if (!USE_COGNITO) {
    return new Promise((resolve) => {
      return resolve({
        error: null,
        groupId: process.env.REACT_APP_DODAY_GROUP_ID ?? '',
        idToken: 'dummy-token',
      })
    })
  }

  const cognitoUser = await Auth.currentAuthenticatedUser().catch((err) => {
    log(`Cognito currentUser error ${err}`)
    return null
  })

  if (!cognitoUser) {
    return {
      error: new Error('no login user'),
      groupId: '',
      idToken: '',
    }
  }

  let groupId = ''
  let token: string = ''
  const attributes = await Auth.userAttributes(cognitoUser).catch((err) => {
    log(`Cognito user attribute error ${err}`)
    return null
  })

  if (!attributes) {
    return {
      error: new Error('get user attribute error'),
      groupId: '',
      idToken: '',
    }
  }

  const session = await Auth.currentSession().catch((err) => {
    log(`Cognito current session error ${err}`)
    return null
  })

  if (!session) {
    return {
      error: new Error('get urrent session error'),
      groupId: '',
      idToken: '',
    }
  }

  token = session.getIdToken().getJwtToken()
  const attr = attributes.find((v: any) => v.getName() === 'custom:groupId')
  if (attr) {
    groupId = attr.getValue()
  }

  log(`User's groupId from Cognito ${groupId}`)
  return {
    error: null,
    groupId: groupId,
    idToken: token,
  }
}

export const fetchTokenfromUserPool = async () => {
  return await fetchTokenAndGroupIdfromUserPool()
}

export const signOut = async () => {
  const ret = await Auth.signOut()
    .then(() => {
      return true
    })
    .catch((err) => {
      log(`sign out error ${err}`)
      return false
    })
  return ret
}
