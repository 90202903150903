import axios from 'axios'
import { TrackerPosition } from '@types'

interface RawLonLat {
  prefix: string
  d: string
  m: string
  s: string
}

interface RawDateTime {
  timezone: string
  date: string
  time: string
}

export interface BackendResponse {
  status: number
  data: any
}

//const GROUP_ID = process.env.REACT_APP_DODAY_GROUP_ID ?? ''
const DEBUG = process.env.REACT_APP_DEBUG ?? false
const baseUrl = `${process.env.REACT_APP_BACKEND_URL}/tracker`

const trackerListPath = (group_id: string): string => `/${group_id}/imei`
const trackerRegisterPath = (group_id: string, imei: string): string =>
  `/${group_id}/register/${imei}`
const trackerPositionsPath = (group_id: string, imei: string): string =>
  `/${group_id}/positions/${imei}`
const trackerDeviceInfoPath = (group_id: string, imei: string): string =>
  `/${group_id}/device-info/${imei}`
const trackerFetchSettingsPath = (group_id: string, imei: string): string =>
  `/${group_id}/settings?imei=${imei}`
const trackerUnregistPath = (group_id: string, imei: string): string =>
  `/${group_id}/unregister/${imei}`
const trackerSettingsPath = (group_id: string, imei: string): string =>
  `/${group_id}/settings/${imei}`

function convertDegreePosition(p: RawLonLat): number {
  /* 経緯度を60進数から10進数に変換する。
  入力データは北緯38度59.12345分 東経138度12.12345分 というデータ形式となっている。
  */
  const di = parseInt(p.d)
  const mi = parseInt(p.m) / 60 //分の整数部分
  const si = parseInt(p.s) / 100000 / 60 //分の少数部分
  let n = di + mi + si
  return p.prefix === 'N' || p.prefix === 'E' ? n : n * -1
}

export const log = (args: any) => {
  if (DEBUG) {
    console.log(args)
  }
}

function convertDateTime(dt: RawDateTime): Date {
  const tz = dt.timezone === 'UTC' ? 'Z' : `${dt.timezone}`
  return new Date(`${dt.date}T${dt.time}.000${tz}`)
}

function httpHeaders(token: string): object {
  return {
    Authorization: `Bearer ${token}`,
    'Content-Type': 'application/json',
    //,'Prefer': 'code=404' //for error case debug by using Prism
  }
}

async function fetchFromBackend(
  url: string,
  token: string,
): Promise<{ status: number; data: any }> {
  log(`fetch url: ${url}`)
  try {
    const res = await axios.get(url, { headers: httpHeaders(token) })
    return { status: res.status, data: res.data }
  } catch (err) {
    if (axios.isAxiosError(err) && err.response) {
      const msg = err.response.data ? JSON.stringify(err.response.data) : ''
      log(`error status=${err.response.status} message=${msg}`)
      return { status: err.response.status, data: err.response.data }
    } else {
      log(`error other error`)
      return { status: 500, data: '' }
    }
  }
}

export async function fetchTrackerList(
  groupId: string,
  token: string,
): Promise<BackendResponse> {
  const url = baseUrl + trackerListPath(groupId)
  const res = await fetchFromBackend(url, token)
  return {
    status: res.status,
    data: res.data,
  }
}

export async function fetchTrackerPositions(
  imei: string,
  groupId: string,
  token: string,
): Promise<BackendResponse> {
  const url = baseUrl + trackerPositionsPath(groupId, imei)
  const res = await fetchFromBackend(url, token)
  let positions: TrackerPosition[] = []
  if (res.status === 200) {
    positions = res.data.positions.map((p: any): TrackerPosition => {
      return {
        imei: p.gnss.imei,
        batteryStatus: p.gnss.status.sufficientBattery,
        batteryVal: p.batteryVal,
        latitude: convertDegreePosition(p.gnss.latitude),
        longitude: convertDegreePosition(p.gnss.longitude),
        datetime: convertDateTime(p.gnss.datetime),
        ciid: p.gnss.ciid,
      }
    })
  } else {
    log(`fetchTrackerPositions unexpected status=${res.status}`)
  }
  return {
    status: res.status,
    data: positions,
  }
}

export async function fetchTrackerDeviceInfo(
  imei: string,
  groupId: string,
  token: string,
): Promise<BackendResponse> {
  const url = baseUrl + trackerDeviceInfoPath(groupId, imei)
  const res = await fetchFromBackend(url, token)
  return {
    status: res.status,
    data: res.data,
  }
}

export async function fetchTrackerSetting(
  imei: string,
  groupId: string,
  token: string,
): Promise<BackendResponse> {
  const url = baseUrl + trackerFetchSettingsPath(groupId, imei)
  const res = await fetchFromBackend(url, token)
  return {
    status: res.status,
    data: res.data,
  }
}

async function sendToBackend(
  url: string,
  body: object,
  token: string,
): Promise<{ status: number; data: any }> {
  log(`post url: ${url}`)
  try {
    const res = await axios.post(url, body, { headers: httpHeaders(token) })
    return { status: res.status, data: res.data }
  } catch (err) {
    if (axios.isAxiosError(err) && err.response) {
      const msg = err.response.data ? JSON.stringify(err.response.data) : ''
      log(`error status=${err.response.status} message=${msg}`)
      return { status: err.response.status, data: err.response.data }
    } else {
      log(`error other error`)
      return { status: 500, data: '' }
    }
  }
}

async function deleteToBackend(
  url: string,
  token: string,
): Promise<{ status: number; data: any }> {
  log(`delete url: ${url}`)
  try {
    const res = await axios.delete(url, { headers: httpHeaders(token) })
    return { status: res.status, data: res.data }
  } catch (err) {
    if (axios.isAxiosError(err) && err.response) {
      const msg = err.response.data ? JSON.stringify(err.response.data) : ''
      log(`error status=${err.response.status} message=${msg}`)
      return { status: err.response.status, data: err.response.data }
    } else {
      log(`error other error`)
      return { status: 500, data: '' }
    }
  }
}

export async function sendRegistration(
  imei: string,
  body: object,
  groupId: string,
  token: string,
): Promise<BackendResponse> {
  const url = baseUrl + trackerRegisterPath(groupId, imei)
  const res = await sendToBackend(url, body, token)
  return {
    status: res.status,
    data: res.data,
  }
}

export async function sendUnregistration(
  imei: string,
  groupId: string,
  token: string,
): Promise<BackendResponse> {
  const url = baseUrl + trackerUnregistPath(groupId, imei)
  const res = await deleteToBackend(url, token)
  return {
    status: res.status,
    data: res.data,
  }
}

export async function sendTrackerSettings(
  imei: string,
  body: object,
  groupId: string,
  token: string,
): Promise<BackendResponse> {
  const url = baseUrl + trackerSettingsPath(groupId, imei)
  const res = await sendToBackend(url, body, token)
  return {
    status: res.status,
    data: res.data,
  }
}
