import * as React from 'react'
import { styled } from '@mui/material/styles'
import MuiDrawer from '@mui/material/Drawer'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import ListSubheader from '@mui/material/ListSubheader'
import SettingsRemoteIcon from '@mui/icons-material/SettingsRemote'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import DeleteIcon from '@mui/icons-material/Delete'
import AssignmentIcon from '@mui/icons-material/Assignment'
import Divider from '@mui/material/Divider'
import { RegistrationDialog } from 'components/RegistrationDialog'
import { UnregistrationDialog } from 'components/UnregistrationDialog'
import { useTrackerContext } from 'hooks/useTrackerContext'
import { useSettingContext } from 'hooks/useSettingContext'
import { useSessionContext } from 'hooks/useSessionContext'
import { TrackerLabel } from '@types'

let drawerMinHeight: number = 0
const drawerMaxHeight: number = 600

type Props = {
  i: number
  item: TrackerLabel
  selectFn: Function
  isSelectedFn: Function
}

type ListProps = {
  trackers: TrackerLabel[]
}

const TrackerRow: React.FC<Props> = ({ i, item, selectFn, isSelectedFn }) => {
  const ii: number = i
  const elm: TrackerLabel = item
  const key = `tracker-list-item-${i}`

  return (
    <React.Fragment>
      <ListItemButton
        selected={isSelectedFn(ii)}
        onClick={() => selectFn(ii)}
        key={key}
      >
        <ListItemIcon>
          <AssignmentIcon />
        </ListItemIcon>
        <ListItemText primary={elm.label} secondary={elm.imei} />
      </ListItemButton>
    </React.Fragment>
  )
}

export const TrackerListItems: React.FC<ListProps> = ({ trackers }) => {
  const { selectTracker } = useTrackerContext()
  const { selectSetting } = useSettingContext()
  const [index, setIndex] = React.useState(-1)
  const [selectedTracker, setTracker] = React.useState({ imei: '' })

  const isSelectedFn = (n: number): boolean => {
    return index === n
  }

  const selectFn = (n: number) => {
    setIndex(n)
    setTracker(trackers[n])
  }

  React.useEffect(() => {
    if (index < 0 || selectedTracker.imei === '') return
    const curentTracker = selectTracker ? selectTracker : () => {}
    curentTracker(selectedTracker.imei)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [index])

  React.useEffect(() => {
    if (index < 0 || selectedTracker.imei === '') return
    const currentSetting = selectSetting ? selectSetting : () => {}
    currentSetting(selectedTracker)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [index])

  return (
    <React.Fragment>
      {trackers.map((ele, i) => {
        return (
          <TrackerRow
            i={i}
            item={ele}
            selectFn={selectFn}
            isSelectedFn={isSelectedFn}
            key={`tracker-row-${i}`}
          ></TrackerRow>
        )
      })}
    </React.Fragment>
  )
}

function getDrawerHeight(): number {
  return drawerMinHeight
}

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop: any) => prop !== 'open',
})(({ theme, open }) => ({
  '& .MuiDrawer-paper': {
    position: 'relative',
    whiteSpace: 'nowrap',
    height: getDrawerHeight(),
    maxHeight: drawerMaxHeight,
    transition: theme.transitions.create('height', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: 'border-box',
    ...(!open && {
      overflowX: 'hidden',
      transition: theme.transitions.create('height', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      height: theme.spacing(1),
      [theme.breakpoints.up('sm')]: {
        height: theme.spacing(1),
      },
    }),
  },
}))

export default function MenuItems() {
  const [open, setOpen] = React.useState(true)
  const toggleDrawer = () => {
    setOpen(!open)
  }
  const { signInState } = useSessionContext()
  const { trackers, trackersCount, updateTrackers } = useTrackerContext()

  React.useEffect(() => {
    const updateAsync = async () => {
      const update = updateTrackers ? updateTrackers : () => []
      await update()
    }
    updateAsync()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signInState])

  drawerMinHeight = trackersCount * 80

  return (
    <React.Fragment>
      <ListItemButton>
        <ListItemIcon>
          <SettingsRemoteIcon />
        </ListItemIcon>
        <ListItemText primary="トラッカー" onClick={toggleDrawer} />
      </ListItemButton>
      <ListSubheader>登録数: {trackersCount}</ListSubheader>
      <Drawer variant="permanent" open={open}>
        <TrackerListItems trackers={trackers} />
      </Drawer>
      <Divider sx={{ my: 1 }} />
      <ListItemButton>
        <ListItemIcon>
          <AddCircleIcon />
        </ListItemIcon>
        <RegistrationDialog />
      </ListItemButton>
      <ListItemButton>
        <ListItemIcon>
          <DeleteIcon />
        </ListItemIcon>
        <UnregistrationDialog />
      </ListItemButton>
    </React.Fragment>
  )
}
