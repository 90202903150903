import { useState, useEffect } from 'react'
import Dashboard from 'dashboard/Dashboard'
import { useSessionContext } from 'hooks/useSessionContext'
import { Authenticator } from '@aws-amplify/ui-react'
import '@aws-amplify/ui-react/styles.css'

type Props = {
  error?: Error
  open: boolean
}

interface AfterSignedInProps {
  user?: any
}

const AfterSignedIn: React.FC<AfterSignedInProps> = ({ user }) => {
  const { signInState, setUserAttributes } = useSessionContext()
  const setAttributes = setUserAttributes ? setUserAttributes : () => {}

  if (user && user.attributes && user.attributes['custom:groupId']) {
    if (signInState?.groupId === '') {
      setAttributes(user)
    }
    return <Dashboard />
  } else {
    return <div>Processing ... </div>
  }
}

const DashboardIfSignIn: React.FC<Props> = ({ error, open }) => {
  if (error) {
    return (
      <Authenticator>
        {({ user }) => <AfterSignedIn user={user} />}
      </Authenticator>
    )
  } else if (!open) {
    return <div>loading....</div>
  } else {
    return <Dashboard />
  }
}

function AppInternal() {
  const { fetchSessionState } = useSessionContext()
  const [hasError, setError] = useState<Error | undefined>()
  const [opened, setOpen] = useState<boolean>(false)

  useEffect(() => {
    const updateError = (err: any) => {
      if (!hasError) {
        setError(err)
      } else if (err && err.message !== hasError.message) {
        setError(err)
      }

      if (!err) {
        setOpen(true)
      }
    }

    const fetchAsync = async (_e: Error | undefined) => {
      const fetchSession = fetchSessionState ? fetchSessionState : () => {}
      const s = await fetchSession()
      return s.error
    }

    fetchAsync(hasError)
      .then((ss) => {
        updateError(ss)
      })
      .catch((ss) => {
        updateError(ss)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [opened])

  return <DashboardIfSignIn error={hasError} open={opened} />
}

export default function App() {
  return <AppInternal />
}
