import React from 'react'
import ReactDOM from 'react-dom/client'
import Leaflet from 'leaflet'
import './index.css'
//import Dashboard from 'dashboard/Dashboard'
import App from 'App'
import 'leaflet/dist/leaflet.css'
import reportWebVitals from './reportWebVitals'
import { SessionContextProvider } from 'hooks/useSessionContext'
import { createTheme, ThemeProvider } from '@mui/material/styles'

Leaflet.Icon.Default.imagePath =
  '//cdnjs.cloudflare.com/ajax/libs/leaflet/1.3.1/images/'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
const mdTheme = createTheme()
root.render(
  <React.StrictMode>
    <ThemeProvider theme={mdTheme}>
      <SessionContextProvider>
        <App />
      </SessionContextProvider>
    </ThemeProvider>
  </React.StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
