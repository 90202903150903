import * as React from 'react'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import ListItemText from '@mui/material/ListItemText'
import Snackbar, { SnackbarCloseReason } from '@mui/material/Snackbar'
import Alert from '@mui/material/Alert'
import { useRegistrationContext } from 'hooks/useRegistrationContext'

type Props = {
  open: boolean
  result: number
  close: Function
}

const RegisterResult: React.FC<Props> = ({ open, result, close }) => {
  if (result === 0) return null

  const handleClose = (
    _event: Event | React.SyntheticEvent<any, Event>,
    reason: SnackbarCloseReason,
  ) => {
    if (reason === 'clickaway') {
      return
    }
    close()
  }

  const serverity = result > 0 ? 'success' : 'error'
  const message = result > 0 ? 'IMEIを登録しました' : 'IMEIを登録に失敗しました'

  return (
    <div>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        open={open}
        onClose={handleClose}
        autoHideDuration={3000}
      >
        <Alert severity={serverity} sx={{ width: '100%' }}>
          {message}
        </Alert>
      </Snackbar>
    </div>
  )
}

export const RegistrationDialog: React.FC = () => {
  const [open, setOpen] = React.useState(false)
  const [disabled, setDisableRegistration] = React.useState(0)

  const [inputLabelError, setInputLabelError] = React.useState(true)
  const [inputIMEIError, setInputIMEIError] = React.useState(false)

  const [errorTextLabel, setErrorTextLabel] = React.useState('')
  const [errorTextIMEI, setErrorTextIMEI] = React.useState('')

  const [label, setLabel] = React.useState('')
  const [imei, setIMEI] = React.useState('')

  const inputIMEIRef = React.useRef<HTMLInputElement>(null)
  const inputLabelRef = React.useRef<HTMLInputElement>(null)

  const [registered, setRegistered] = React.useState(0)
  const [snackopen, setSnackOpen] = React.useState(false)

  const { registerTracker } = useRegistrationContext()
  const register = registerTracker || (() => {})

  const resetStatus = () => {
    setDisableRegistration(0)
    setInputLabelError(false)
    setInputIMEIError(false)
    setErrorTextIMEI('')
    setErrorTextLabel('')
  }

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
    resetStatus()
  }

  const handleRegister = async () => {
    if (label.length > 0 && imei.length > 0) {
      const res = await register({ label: label, imei: imei })
      setRegistered(res ? 1 : -1)
      setSnackOpen(true)
    }

    setOpen(false)
    resetStatus()
  }

  const handleChangeLabel = () => {
    const ref = inputLabelRef.current
    if (ref) {
      if (!ref.validity.valid) {
        setInputLabelError(true)
        const v = disabled & ~2
        setDisableRegistration(v)
        setErrorTextLabel('ラベルなしは登録できません')
        setLabel('')
      } else {
        setInputLabelError(false)
        const v = disabled | 2
        setDisableRegistration(v)
        setErrorTextLabel('')
        setLabel(ref.value)
      }
    }
  }

  const handleChangeIMEI = () => {
    const ref = inputIMEIRef.current
    if (ref) {
      if (!ref.validity.valid) {
        setInputIMEIError(true)
        const v = disabled & ~1
        setDisableRegistration(v)
        setErrorTextIMEI('15桁の数字を入力してください')
        setIMEI('')
      } else {
        setInputIMEIError(false)
        const v = disabled | 1
        setDisableRegistration(v)
        setErrorTextIMEI('')
        setIMEI(ref.value)
      }
    }
  }

  const closeSnack = () => {
    setSnackOpen(false)
    setRegistered(0)
  }

  return (
    <React.Fragment>
      <div>
        <RegisterResult
          open={snackopen}
          result={registered}
          close={closeSnack}
        />
      </div>
      <ListItemText primary="IMEIを登録する" onClick={handleClickOpen} />
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>IMEIを登録する</DialogTitle>
        <DialogContent>
          <DialogContentText>
            位置情報を保存するGPSトラッカーのIMEIを登録します。
          </DialogContentText>
          <TextField
            autoFocus
            error={inputLabelError}
            margin="dense"
            id="trackerlabel"
            label="ラベル名"
            type="text"
            fullWidth
            variant="standard"
            inputProps={{ pattern: '.{1,}', minLength: 1, required: true }}
            inputRef={inputLabelRef}
            onChange={handleChangeLabel}
            helperText={errorTextLabel}
          />
          <TextField
            error={inputIMEIError}
            margin="dense"
            id="imei"
            label="IMEI"
            type="text"
            fullWidth
            variant="standard"
            inputProps={{
              inputMode: 'numeric',
              pattern: '[0-9]{15,15}',
              maxLength: 15,
              required: true,
            }}
            inputRef={inputIMEIRef}
            onChange={handleChangeIMEI}
            helperText={errorTextIMEI}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>キャンセル</Button>
          <Button onClick={handleRegister} disabled={disabled !== 3}>
            登録する
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}
