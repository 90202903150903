import React, { createContext, useState, useContext } from 'react'
import {
  fetchTokenfromUserPool,
  getGroupIdfromAttributes,
  SignInState,
} from 'backend/auth'
import { log } from 'backend/api'
import { AmplifyUser } from '@aws-amplify/ui'
interface SessionContextProviderParam {
  children: React.ReactNode
}

interface SessionContextType {
  fetchSessionState?: Function
  setUserAttributes?: Function
  signInState?: SignInState
}

const SessionContext: React.Context<SessionContextType> = createContext({})

export const SessionContextProvider: React.FC<SessionContextProviderParam> = ({
  children,
}) => {
  const [signInState, setSignInState] = useState<SignInState>({
    error: new Error('init'),
    groupId: '',
    idToken: '',
  })

  const fetchSessionState = async () => {
    const session = await fetchTokenfromUserPool()
    setSignInState(session)
    return session
  }

  const setUserAttributes = (user?: AmplifyUser) => {
    let session = signInState
    if (user && user.attributes) {
      log(`warning shows after this log. setUserAttributes ${user.attributes}`)
      session = getGroupIdfromAttributes(user.attributes)
      setSignInState(session)
    }
    return session
  }

  return (
    <SessionContext.Provider
      value={{
        fetchSessionState,
        setUserAttributes,
        signInState,
      }}
    >
      {children}
    </SessionContext.Provider>
  )
}

export const useSessionContext = () => useContext(SessionContext)
