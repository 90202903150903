import * as React from 'react'
import Box from '@mui/material/Box'
import dayjs from 'dayjs'
import TextField from '@mui/material/TextField'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import FormLabel from '@mui/material/FormLabel'
import { TimePicker } from '@mui/x-date-pickers/TimePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import InputAdornment from '@mui/material/InputAdornment'
import Input from '@mui/material/Input'
import InputLabel from '@mui/material/InputLabel'
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import Paper from '@mui/material/Paper'
import Snackbar, { SnackbarCloseReason } from '@mui/material/Snackbar'
import Alert from '@mui/material/Alert'
import { useSettingContext } from 'hooks/useSettingContext'
import { TrackerDeviceInfo } from '@types'

type Props = {
  open: boolean
  result: number
  close: Function
}

const DEFAULT = 0
const SUCCESS = 1
const ERROR = -1

const RegisterResult: React.FC<Props> = ({ open, result, close }) => {
  if (result === DEFAULT) return null

  const handleClose = (
    _event: Event | React.SyntheticEvent<any, Event>,
    reason: SnackbarCloseReason,
  ) => {
    if (reason === 'clickaway') {
      return
    }
    close()
  }

  const serverity = result > 0 ? 'success' : 'error'
  const message =
    result > 0
      ? 'トラッカーの設定値の送信に成功しました'
      : 'トラッカーの設定値の送信に失敗しました'

  return (
    <div>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        open={open}
        onClose={handleClose}
        autoHideDuration={3000}
      >
        <Alert severity={serverity} sx={{ width: '100%' }}>
          {message}
        </Alert>
      </Snackbar>
    </div>
  )
}

const SettingView: React.FC = () => {
  const { settings, submitSettings } = useSettingContext()
  const { label, deviceSetting } = settings || {
    label: '',
    deviceSetting: {
      settingStatus: 'request',
      acsWorkMode: 0,
      acsInterval: 3,
      acsStartHour: 0,
      acsStartMinute: 0,
      acsStartDelay: 0,
    },
  }
  const {
    acsWorkMode,
    acsInterval,
    acsStartHour,
    acsStartMinute,
    acsStartDelay,
  } = deviceSetting
  const defaultDisable = acsWorkMode === 0 ? true : false
  const defaultStartTime = new Date()
  defaultStartTime.setHours(acsStartHour)
  defaultStartTime.setMinutes(acsStartMinute)

  const [trackerLabel, setTrackerLabel] = React.useState(label)
  const [intervalMinutes, setIntervalMinutes] = React.useState(acsInterval)
  const [workMode, setWorkMode] = React.useState(`${acsWorkMode || 0}`)
  const [disable, setDisable] = React.useState(defaultDisable)
  const [startTime, setStartTime] = React.useState(dayjs(defaultStartTime))
  const [delayStartingMinutes, setDelayStartingMinutes] =
    React.useState(acsStartDelay)

  const [sentsettings, setSentSettings] = React.useState(DEFAULT)
  const [snackopen, setSnackOpen] = React.useState(false)

  const handleLabelChanged = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setTrackerLabel(event.target.value)
  }

  const handleTimeChange = (newValue: any) => {
    setStartTime(newValue)
  }

  const handleRadioChanged = (
    _event: React.ChangeEvent<HTMLInputElement>,
    value: string,
  ): void => {
    setDisable(value !== '1')
    setWorkMode(value)
  }

  const handleDelayStartingChanged = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ): void => {
    const min = 0
    const max = 99
    const value = Math.max(min, Math.min(max, Number(event.target.value)))
    setDelayStartingMinutes(value)
  }

  const handleIntervalChanged = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ): void => {
    const min = 3
    const max = 1440
    const value = Math.max(min, Math.min(max, Number(event.target.value)))
    setIntervalMinutes(value)
  }

  const submit = async () => {
    if (!submitSettings) return
    const s = {
      label: trackerLabel,
      deviceSetting: {
        settingStatus: 'request',
        acsWorkMode: parseInt(workMode),
        acsInterval: intervalMinutes,
        acsStartHour: startTime.hour(),
        acsStartMinute: startTime.minute(),
        acsStartDelay: delayStartingMinutes,
      },
    }
    const ret = await submitSettings(s)
    setSnackOpen(true)
    setSentSettings(ret ? SUCCESS : ERROR)
  }

  const closeSnack = () => {
    setSentSettings(DEFAULT)
    setSnackOpen(false)
  }

  return (
    <React.Fragment>
      <RegisterResult
        open={snackopen}
        result={sentsettings}
        close={closeSnack}
      />
      <Box component="form" noValidate autoComplete="on">
        <Stack
          direction={{ xs: 'column', sm: 'column', md: 'row', lg: 'row' }}
          spacing={{ xs: 3, sm: 3, md: 3, lg: 2 }}
          alignItems="center"
          justifyContent="space-around"
        >
          <TextField
            id="standard-basic"
            value={`${trackerLabel}`}
            variant="standard"
            label="トラッカー名称"
            onChange={handleLabelChanged}
          />
          <FormControl variant="standard" sx={{ m: 1, mt: 3, width: '25ch' }}>
            <InputLabel htmlFor="interval-input-text">
              インターバル(分)
            </InputLabel>
            <Input
              id="interval-input-text"
              endAdornment={<InputAdornment position="end">分</InputAdornment>}
              inputProps={{
                'aria-label': 'インターバル',
              }}
              value={intervalMinutes}
              type="number"
              onChange={handleIntervalChanged}
            />
          </FormControl>
          <FormControl>
            <FormLabel id="radio-buttons-group-label">動作モード</FormLabel>
            <RadioGroup
              row
              aria-labelledby="radio-buttons-group-label"
              value={workMode}
              name="radio-buttons-group"
              onChange={handleRadioChanged}
            >
              <FormControlLabel
                value="0"
                control={<Radio />}
                label="Measure Now"
              />
              <FormControlLabel
                value="1"
                control={<Radio />}
                label="Measure At Moment"
              />
            </RadioGroup>
          </FormControl>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <TimePicker
              label="測定開始時刻"
              value={startTime}
              onChange={handleTimeChange}
              renderInput={(params) => <TextField {...params} />}
              disabled={disable}
            />
          </LocalizationProvider>
          <FormControl variant="standard" sx={{ m: 1, mt: 3, width: '25ch' }}>
            <InputLabel htmlFor="delay-starting-input-text">
              測定開始遅延時間(分)
            </InputLabel>
            <Input
              id="delay-starting-input-text"
              endAdornment={<InputAdornment position="end">分</InputAdornment>}
              inputProps={{
                'aria-label': '測定開始遅延時間',
              }}
              value={delayStartingMinutes}
              type="number"
              onChange={handleDelayStartingChanged}
              disabled={disable}
            />
          </FormControl>
          <Button variant="contained" onClick={submit}>
            設定を変更する
          </Button>
        </Stack>
      </Box>
    </React.Fragment>
  )
}

const DeviceInfoView: React.FC = () => {
  const { deviceinfo } = useSettingContext()
  const dinfo: TrackerDeviceInfo = deviceinfo || { imsi: '', fwversion: '' }
  return (
    <React.Fragment>
      <Grid container spacing={4}>
        <Grid item md={6}>
          <Typography variant="caption">IMSI: {dinfo.imsi}</Typography>
        </Grid>
        <Grid item md={6}>
          <Typography variant="caption">FW ver. {dinfo.fwversion}</Typography>
        </Grid>
      </Grid>
    </React.Fragment>
  )
}

export const Settings: React.FC = () => {
  return (
    <React.Fragment>
      <Card variant="outlined" sx={{ padding: '8px' }}>
        <DeviceInfoView />
      </Card>
      <Paper sx={{ padding: '8px' }} elevation={0}>
        <SettingView />
      </Paper>
    </React.Fragment>
  )
}
