import * as React from 'react'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import ListItemText from '@mui/material/ListItemText'
import Snackbar, { SnackbarCloseReason } from '@mui/material/Snackbar'
import Alert from '@mui/material/Alert'
import { useRegistrationContext } from 'hooks/useRegistrationContext'

type Props = {
  open: boolean
  result: number
  close: Function
}

const RegisterResult: React.FC<Props> = ({ open, result, close }) => {
  if (result === 0) return null

  const handleClose = (
    _event: Event | React.SyntheticEvent<any, Event>,
    reason: SnackbarCloseReason,
  ) => {
    if (reason === 'clickaway') {
      return
    }
    close()
  }

  const serverity = result > 0 ? 'success' : 'error'
  const message =
    result > 0 ? 'トラッカーを削除しました' : 'トラッカーの削除に失敗しました'

  return (
    <div>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        open={open}
        onClose={handleClose}
        autoHideDuration={3000}
      >
        <Alert severity={serverity} sx={{ width: '100%' }}>
          {message}
        </Alert>
      </Snackbar>
    </div>
  )
}

export const UnregistrationDialog: React.FC = () => {
  const [open, setOpen] = React.useState(false)
  const [disabled, setDisableUnregistration] = React.useState(true)

  const [inputIMEIError, setInputIMEIError] = React.useState(false)
  const [errorTextIMEI, setErrorTextIMEI] = React.useState('')
  const inputIMEIRef = React.useRef<HTMLInputElement>(null)
  const [imei, setIMEI] = React.useState('')

  const [unregistered, setUnregistered] = React.useState(0)
  const [snackopen, setSnackOpen] = React.useState(false)

  const { unregisterTracker } = useRegistrationContext()
  const unregister = unregisterTracker || (() => {})

  const resetStatus = () => {
    setDisableUnregistration(true)
    setInputIMEIError(false)
    setErrorTextIMEI('')
  }

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
    resetStatus()
  }

  const handleUnregister = async () => {
    if (imei.length > 0) {
      const res = await unregister({ imei: imei })
      setUnregistered(res ? 1 : -1)
      setSnackOpen(true)
    }
    setOpen(false)
    resetStatus()
  }

  const handleChangeIMEI = () => {
    if (inputIMEIRef.current) {
      const ref = inputIMEIRef.current
      if (!ref.validity.valid) {
        setInputIMEIError(true)
        const v = true
        setDisableUnregistration(v)
        setErrorTextIMEI('15桁の数字を入力してください')
      } else {
        setInputIMEIError(false)
        const v = false
        setDisableUnregistration(v)
        setErrorTextIMEI('')
        setIMEI(ref.value)
      }
    }
  }

  const closeSnack = () => {
    setSnackOpen(false)
    setUnregistered(0)
  }

  return (
    <React.Fragment>
      <div>
        <RegisterResult
          open={snackopen}
          result={unregistered}
          close={closeSnack}
        />
      </div>
      <ListItemText primary="トラッカーを削除する" onClick={handleClickOpen} />
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>トラッカーを削除する</DialogTitle>
        <DialogContent>
          <DialogContentText>
            GPSトラッカーの登録を解除し、位置情報を削除します。削除するIMEIを入力して下さい。
          </DialogContentText>
          <TextField
            error={inputIMEIError}
            margin="dense"
            id="imei"
            label="IMEI"
            type="text"
            fullWidth
            variant="standard"
            inputProps={{
              inputMode: 'numeric',
              pattern: '[0-9]{15,15}',
              maxLength: 15,
              required: true,
            }}
            inputRef={inputIMEIRef}
            onChange={handleChangeIMEI}
            helperText={errorTextIMEI}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>キャンセル</Button>
          <Button onClick={handleUnregister} disabled={disabled}>
            削除する
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}
