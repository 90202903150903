import Typography from '@mui/material/Typography'
import v from 'version.json'

function CopyrightLabel(props: any) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {'© '}
      {new Date().getFullYear()} ACCESS CO., LTD.
      {` ver. ${v.version}`}
    </Typography>
  )
}

export default function Copyright(props: any) {
  return <CopyrightLabel props={props} />
}
